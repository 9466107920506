import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";

import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

import { JuridicalPersonForm } from "./JuridicalPersonForm";
import { PhysicalPersonForm } from "./PhysicalPersonForm";

import { format, differenceInDays } from "date-fns";

import { makeBookingRequest } from "../api";

import {
  setSmallZada,
  setLegalEntity,
  setBookingModalIsVisible,
  setBookingComplete,
} from "../redux/bookingSlice";

export const BookingForm = (props) => {
  const {
    checkIn,
    checkOut,
    firstName,
    lastName,
    email,
    phone,
    companyName,
    companyID,
    smallZada,
    legalEntity,
  } = useSelector((state) => state.bookingDetails);

  const dispatch = useDispatch();

  return (
    <Paper
      elevation={4}
      sx={{
        position: "fixed",
        background: "white",
        padding: "24px 40px",
        borderRadius: "4px",
        width: "460px",
      }}
    >
      <Button
        sx={{
          position: "absolute",
          right: "0",
          top: "0",
          minWidth: "32px",
        }}
        onClick={() => {
          dispatch(setBookingModalIsVisible(false));
        }}
      >
        <CloseIcon />
      </Button>
      <Box
        sx={{
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        <Box sx={{ fontSize: "20px" }}>
          Date de facturare pentru sejurul
        </Box>
        <Box>
          {`${format(checkIn, "dd.MM.yyyy")} - ${format(
            checkOut,
            "dd.MM.yyyy"
          )} (${differenceInDays(
            checkOut,
            checkIn
          )} nopti)`}
        </Box>
      </Box>
      <Box>
        <Box>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{ fontSize: "12px" }}
            value={legalEntity}
            onChange={(e) =>
              dispatch(setLegalEntity(e.target.value))
            }
          >
            <FormControlLabel
              value="physical"
              control={<Radio size="small" />}
              label="Persoana fizica"
            />
            <FormControlLabel
              value="juridical"
              control={<Radio size="small" />}
              label="Persoana juridica"
            />
          </RadioGroup>
        </Box>
        <Box>
          {legalEntity === "physical" ? (
            <PhysicalPersonForm />
          ) : (
            <JuridicalPersonForm
              legalEntity={legalEntity}
            />
          )}
        </Box>
        <Box
          sx={{ marginTop: "12px", marginLeft: "-11px" }}
        >
          <Checkbox
            size="small"
            checked={smallZada}
            onChange={(e) => {
              dispatch(setSmallZada(e.target.checked));
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
          doresc sa rezerv si casuta Zada Mica
          <Tooltip title="costul este de 400 RON / noapte; se poate opta si ulterior, cu minim 2 zile inainte de inceperea sejurului">
            <InfoIcon
              sx={{ fontSize: "14px", marginLeft: "6px" }}
            />
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Box
            sx={{ fontSize: "18px", fontWeight: "bold" }}
          >
            Total de plata
          </Box>
          <Box sx={{ fontSize: "16px", fontWeight: "500" }}>
            {/* 3,800 RON */}
            {`${
              parseInt(
                differenceInDays(checkOut, checkIn)
              ) *
                1200 +
              (smallZada
                ? 400 * differenceInDays(checkOut, checkIn)
                : 0)
            }  RON`}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "24px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              makeBookingRequest(checkIn, checkOut, {
                firstName,
                lastName,
                email,
                phone,
                companyName,
                companyID,
                legalEntity,
                smallZada,
              });

              dispatch(setBookingModalIsVisible(false));
              dispatch(setBookingComplete());
            }}
          >
            Trimite solicitarea
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};
