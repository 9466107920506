import React from "react";
import { Box, TextField } from "@mui/material";

import { Row } from "./Generics";

import { useSelector, useDispatch } from "react-redux";

import {
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  setCompanyID,
  setCompanyName,
} from "../redux/bookingSlice";

import { gsap } from "gsap";

export const JuridicalPersonForm = (props) => {
  const { isMobile = false, legalEntity } = props;

  const {
    firstName,
    lastName,
    email,
    phone,
    companyName,
    companyID,
  } = useSelector((state) => state.bookingDetails);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isMobile) {
      if (legalEntity === "physical") {
        // set height of nume firma and cui to 0
        gsap.to("#companyName", {
          overflow: "hidden",
          marginTop: 0,
          height: 0,
          duration: "0.3",
        });
        gsap.to("#companyNumber", {
          overflow: "hidden",
          marginTop: 0,
          height: 0,
          duration: "0.3",
        });
      } else {
        gsap.to("#companyName", {
          height: 40,
          marginTop: 16,
          duration: "0.3",
          overflow: "visible",
        });
        gsap.to("#companyNumber", {
          height: 40,
          marginTop: 16,
          duration: "0.3",
          overflow: "visible",
        });
      }
    }
  }, [legalEntity, isMobile]);

  return (
    <Box>
      {!isMobile ? (
        <Row>
          <TextField
            label="Nume firma"
            placeholder="Nume firma"
            size="small"
            value={companyName}
            onChange={(e) => {
              dispatch(setCompanyName(e.target.value));
            }}
          />
          <TextField
            label="CUI"
            placeholder="CUI"
            size="small"
            value={companyID}
            onChange={(e) => {
              dispatch(setCompanyID(e.target.value));
            }}
          />
        </Row>
      ) : (
        <>
          <Row
            id="companyNumber"
            sx={
              legalEntity === "physical"
                ? {
                    overflow: "hidden",
                    marginTop: 0,
                    height: 0,
                  }
                : {
                    height: "40px",
                    marginTop: "16px",
                    overflow: "visible",
                  }
            }
          >
            <TextField
              label="CUI"
              placeholder="CUI"
              size="small"
              value={companyID}
              onChange={(e) => {
                dispatch(setCompanyID(e.target.value));
              }}
              sx={{ width: "100%" }}
            />
          </Row>
          <Row
            id="companyName"
            sx={
              legalEntity === "physical"
                ? {
                    overflow: "hidden",
                    marginTop: 0,
                    height: 0,
                  }
                : {
                    height: "40px",
                    marginTop: "16px",
                    overflow: "visible",
                  }
            }
          >
            <TextField
              label="Nume firma"
              placeholder="Nume firma"
              size="small"
              value={companyName}
              onChange={(e) => {
                dispatch(setCompanyName(e.target.value));
              }}
              sx={{ width: "100%" }}
            />
          </Row>
        </>
      )}

      {legalEntity === "juridical" ? (
        <Box sx={{ marginTop: "22px" }}>
          Informatii delegat
        </Box>
      ) : null}
      <Row>
        <TextField
          label="Nume"
          placeholder="Nume"
          size="small"
          value={lastName}
          onChange={(e) => {
            dispatch(setLastName(e.target.value));
          }}
        />
        <TextField
          label="Prenume"
          placeholder="Prenume"
          size="small"
          value={firstName}
          onChange={(e) => {
            dispatch(setFirstName(e.target.value));
          }}
        />
      </Row>

      {!isMobile ? (
        <Row>
          <TextField
            label="Telefon"
            placeholder="Telefon"
            size="small"
            value={phone}
            onChange={(e) => {
              dispatch(setPhone(e.target.value));
            }}
            type="tel"
          />
          <TextField
            label="Email"
            placeholder="Email"
            size="small"
            value={email}
            onChange={(e) => {
              dispatch(setEmail(e.target.value));
            }}
            type="email"
          />
        </Row>
      ) : (
        <>
          <Row>
            <TextField
              label="Email"
              placeholder="Email"
              size="small"
              value={email}
              onChange={(e) => {
                dispatch(setEmail(e.target.value));
              }}
              sx={{ width: "100%" }}
              type="email"
            />
          </Row>
          <Row>
            <TextField
              label="Telefon"
              placeholder="Telefon"
              size="small"
              value={phone}
              onChange={(e) => {
                dispatch(setPhone(e.target.value));
              }}
              sx={{ width: "100%" }}
              // inputProps={{ inputMode: "numeric" }}
              type="tel"
            />
          </Row>
        </>
      )}
      {/* <Row>
        <TextField
          label="Telefon"
          placeholder="Telefon"
          size="small"
          value={phone}
          onChange={(e) => {
            dispatch(setPhone(e.target.value));
          }}
          type="tel"
        />
        <TextField
          label="Email"
          placeholder="Email"
          size="small"
          value={email}
          onChange={(e) => {
            dispatch(setEmail(e.target.value));
          }}
          type="email"
        />
      </Row> */}
    </Box>
  );
};
