import React from "react";
import { Box, TextField, Tab, Tabs } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";

import { Row } from "./Generics";

import {
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  setCompanyID,
  setCompanyName,
} from "../redux/bookingSlice";

export const LegalEntityForm = (props) => {
  const { legalEntity } = props;

  const [selectedTab, setSelectedTab] = React.useState(0);

  const {
    firstName,
    lastName,
    email,
    phone,
    companyName,
    companyID,
  } = useSelector((state) => state.bookingDetails);
  const dispatch = useDispatch();

  if (legalEntity === "juridical") {
    return (
      <>
        <Box>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => {
              setSelectedTab(newValue);
            }}
            aria-label="basic tabs example"
          >
            <Tab label="Detalii firma" sx={{ flex: 1 }} />
            <Tab label="Detalii delegat" sx={{ flex: 1 }} />
          </Tabs>
        </Box>
        <CustomTabPanel value={selectedTab} index={0}>
          <Row
            id="companyNumber"
            sx={
              legalEntity === "physical"
                ? {
                    overflow: "hidden",
                    marginTop: 0,
                    height: 0,
                  }
                : {
                    height: "40px",
                    marginTop: "16px",
                    overflow: "visible",
                  }
            }
          >
            <TextField
              label="CUI"
              placeholder="CUI"
              size="small"
              value={companyID}
              onChange={(e) => {
                dispatch(setCompanyID(e.target.value));
              }}
              sx={{ width: "100%" }}
            />
          </Row>
          <Row
            id="companyName"
            sx={
              legalEntity === "physical"
                ? {
                    overflow: "hidden",
                    marginTop: 0,
                    height: 0,
                  }
                : {
                    height: "40px",
                    marginTop: "16px",
                    overflow: "visible",
                  }
            }
          >
            <TextField
              label="Nume firma"
              placeholder="Nume firma"
              size="small"
              value={companyName}
              onChange={(e) => {
                dispatch(setCompanyName(e.target.value));
              }}
              sx={{ width: "100%" }}
            />
          </Row>
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={1}>
          <Row>
            <TextField
              label="Nume"
              placeholder="Nume"
              size="small"
              value={lastName}
              onChange={(e) => {
                dispatch(setLastName(e.target.value));
              }}
            />
            <TextField
              label="Prenume"
              placeholder="Prenume"
              size="small"
              value={firstName}
              onChange={(e) => {
                dispatch(setFirstName(e.target.value));
              }}
            />
          </Row>
          <Row>
            <TextField
              label="Email"
              placeholder="Email"
              size="small"
              value={email}
              onChange={(e) => {
                dispatch(setEmail(e.target.value));
              }}
              sx={{ width: "100%" }}
              type="email"
            />
          </Row>
          <Row>
            <TextField
              label="Telefon"
              placeholder="Telefon"
              size="small"
              value={phone}
              onChange={(e) => {
                dispatch(setPhone(e.target.value));
              }}
              sx={{ width: "100%" }}
              // inputProps={{ inputMode: "numeric" }}
              type="tel"
            />
          </Row>
        </CustomTabPanel>
      </>
    );
  } else {
    return (
      <Box>
        <Row>
          <TextField
            label="Nume"
            placeholder="Nume"
            size="small"
            value={lastName}
            onChange={(e) => {
              dispatch(setLastName(e.target.value));
            }}
          />
          <TextField
            label="Prenume"
            placeholder="Prenume"
            size="small"
            value={firstName}
            onChange={(e) => {
              dispatch(setFirstName(e.target.value));
            }}
          />
        </Row>
        <Row>
          <TextField
            label="Email"
            placeholder="Email"
            size="small"
            value={email}
            onChange={(e) => {
              dispatch(setEmail(e.target.value));
            }}
            sx={{ width: "100%" }}
            type="email"
          />
        </Row>
        <Row>
          <TextField
            label="Telefon"
            placeholder="Telefon"
            size="small"
            value={phone}
            onChange={(e) => {
              dispatch(setPhone(e.target.value));
            }}
            sx={{ width: "100%" }}
            // inputProps={{ inputMode: "numeric" }}
            type="tel"
          />
        </Row>
      </Box>
    );
  }
};

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};
