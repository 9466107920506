import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import DoneIcon from "@mui/icons-material/Done";
import tree1 from "../assets/images/icons/tree1.png";
import tree2 from "../assets/images/icons/tree2.png";

export const Section = (props) => {
  const { title, items, icon, iconImg } = props;
  return (
    <div className="section">
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          // justifyContent: "center",
        }}
        className="section-title"
      >
        {iconImg ? (
          <img
            src={iconImg}
            title=""
            alt=""
            style={{
              width: "40px",
              height: "40px",
              marginRight: "12px",
            }}
          />
        ) : null}
        <div
          style={{ fontSize: "20px", fontFamily: "Roboto" }}
        >
          {title}
        </div>
      </div>
      {icon === 1 ? (
        <div>
          <ul>
            {items.map((item, index) => (
              <li key={`${title}_${index}`}>{item}</li>
            ))}
          </ul>
        </div>
      ) : null}
      {icon === 2 ? (
        <div>
          {items.map((item, index) => (
            <div
              style={{ display: "flex" }}
              key={`${title}_${index}`}
            >
              <div style={{ marginRight: "6px" }}>
                <ArrowRightAltIcon />
              </div>
              <div>{item}</div>
            </div>
          ))}
        </div>
      ) : null}
      {icon === 3 ? (
        <div>
          {items.map((item, index) => (
            <div
              style={{ display: "flex" }}
              key={`${title}_${index}`}
            >
              <div style={{ marginRight: "6px" }}>
                <DoneIcon />
              </div>
              <div>{item}</div>
            </div>
          ))}
        </div>
      ) : null}
      {icon === 4 ? (
        <div>
          {items.map((item, index) => (
            <div
              style={{ display: "flex" }}
              key={`${title}_${index}`}
            >
              <div style={{ marginRight: "6px" }}>
                <img
                  src={tree1}
                  alt=""
                  style={{ width: "22px" }}
                />
              </div>
              <div>{item}</div>
            </div>
          ))}
        </div>
      ) : null}
      {icon === 5 ? (
        <div>
          {items.map((item, index) => (
            <div
              key={`${title}_${index}`}
              style={{ display: "flex" }}
            >
              <div style={{ marginRight: "6px" }}>
                <img
                  src={tree2}
                  alt=""
                  style={{ width: "22px" }}
                />
              </div>
              <div>{item}</div>
            </div>
          ))}
        </div>
      ) : null}
      {icon === 6 ? (
        <div>
          {items.map((item, index) => (
            <div
              key={`${title}_${index}`}
              style={{ display: "flex" }}
            >
              <div style={{ marginRight: "6px" }}>
                <DoneIcon />
              </div>
              <div>{item}</div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
