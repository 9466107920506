import React from "react";
import { Fab } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { ImageComponent } from "./ImageComponent";

export const Carousel = (props) => {
  const { imageWidth, imageHeight } = props;

  const imageObjects = [
    {
      lowRes: "thumb1.webp",
      highRes: "hd1.webp",
      vLowRes: "low1.webp",
    },
    {
      lowRes: "thumb2.webp",
      highRes: "hd2.webp",
      vLowRes: "low2.webp",
    },
    {
      lowRes: "thumb4.webp",
      highRes: "hd4.webp",
      vLowRes: "low4.webp",
    },
    { lowRes: "thumb5.webp", highRes: "hd5.webp" },
    { lowRes: "thumb6.webp", highRes: "hd6.webp" },
    { lowRes: "thumb7.webp", highRes: "hd7.webp" },
    { lowRes: "thumb8.webp", highRes: "hd8.webp" },
    { lowRes: "thumb9.webp", highRes: "hd9.webp" },
    { lowRes: "thumb10.webp", highRes: "hd10.webp" },
    { lowRes: "thumb11.webp", highRes: "hd11.webp" },
    { lowRes: "thumb12.webp", highRes: "hd12.webp" },
    { lowRes: "thumb13.webp", highRes: "hd13.webp" },
    { lowRes: "thumb14.webp", highRes: "hd14.webp" },
  ];

  const [imageInFocusIndex, setImageInFocusIndex] =
    React.useState(0);
  const [carouselOffset, setCarouselOffset] =
    React.useState(0);

  return (
    <div
      className="carousel-wrapper"
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        // height: imageHeight,
      }}
    >
      {imageInFocusIndex >= 1 ? (
        <Fab
          style={{
            position: "absolute",
            marginLeft: "30px",
          }}
          onClick={() => {
            setImageInFocusIndex(imageInFocusIndex - 1);
            const imageWidth = document.querySelector(
              ".carousel-img-item"
            ).clientWidth;

            setCarouselOffset(
              (imageInFocusIndex - 1) * (imageWidth + 3)
            );
          }}
        >
          <ArrowLeftIcon style={{ fontSize: "32px" }} />
        </Fab>
      ) : null}
      <div style={{ overflow: "hidden" }}>
        <div
          style={{
            display: "flex",
            transform: `translate(-${carouselOffset}px, 0px)`,
            transition: "0.5s all ",
          }}
        >
          {imageObjects.map((image, index) =>
            imageInFocusIndex + 3 >= index ? (
              <ImageComponent
                {...image}
                index={index}
                key={index}
              />
            ) : (
              <div>Loading ...</div>
            )
          )}
        </div>
      </div>
      {imageInFocusIndex < imageObjects.length - 2 ? (
        <Fab
          style={{
            position: "absolute",
            right: "30px",
          }}
          onClick={() => {
            setImageInFocusIndex(imageInFocusIndex + 1);
            const imageWidth = document.querySelector(
              ".carousel-img-item"
            ).clientWidth;

            if (
              imageInFocusIndex + 1 ===
              imageObjects.length - 2
            ) {
              setCarouselOffset(
                (imageInFocusIndex + 1) * (imageWidth + 3) -
                  ((imageInFocusIndex + 1) * 2 + 110)
              );
            } else {
              setCarouselOffset(
                (imageInFocusIndex + 1) * (imageWidth + 3)
              );
            }
          }}
        >
          <ArrowRightIcon style={{ fontSize: "32px" }} />
        </Fab>
      ) : null}
    </div>
  );
};
