import React from "react";
import { Box, TextField } from "@mui/material";
import { Row } from "./Generics";

import { useSelector, useDispatch } from "react-redux";

import {
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
} from "../redux/bookingSlice";

export const PhysicalPersonForm = (props) => {
  const { isMobile = false } = props;

  const { firstName, lastName, email, phone } = useSelector(
    (state) => state.bookingDetails
  );
  const dispatch = useDispatch();

  return (
    <Box>
      <Row>
        <TextField
          label="Nume"
          placeholder="Nume"
          size="small"
          value={lastName}
          onChange={(e) => {
            dispatch(setLastName(e.target.value));
          }}
        />
        <TextField
          label="Prenume"
          placeholder="Prenume"
          size="small"
          value={firstName}
          onChange={(e) => {
            dispatch(setFirstName(e.target.value));
          }}
        />
      </Row>
      {!isMobile ? (
        <Row>
          <TextField
            label="Telefon"
            placeholder="Telefon"
            size="small"
            value={phone}
            onChange={(e) => {
              dispatch(setPhone(e.target.value));
            }}
            type="tel"
          />
          <TextField
            label="Email"
            placeholder="Email"
            size="small"
            value={email}
            onChange={(e) => {
              dispatch(setEmail(e.target.value));
            }}
            type="email"
          />
        </Row>
      ) : (
        <>
          <Row>
            <TextField
              label="Email"
              placeholder="Email"
              size="small"
              value={email}
              onChange={(e) => {
                dispatch(setEmail(e.target.value));
              }}
              sx={{ width: "100%" }}
              type="email"
            />
          </Row>
          <Row>
            <TextField
              label="Telefon"
              placeholder="Telefon"
              size="small"
              value={phone}
              onChange={(e) => {
                dispatch(setPhone(e.target.value));
              }}
              sx={{ width: "100%" }}
              // inputProps={{ inputMode: "numeric" }}
              type="tel"
            />
          </Row>
        </>
      )}
    </Box>
  );
};
