import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
// import { LightApp } from "./components/LightApp";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// import { NotFound } from "./components/NotFound";
// import { AdminDahsboardV2 } from "./components/adminDashboard/AdminDashboardV2";
// import { PrivateRoute } from "./components/adminDashboard/PrivateRoute";
import { Provider } from "react-redux";
import store from "./store";

// import { Login } from "./components/adminDashboard/Login";

import "./i18n";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    // errorElement: <NotFound />,
  },
  // {
  //   path: "/admin/login",
  //   element: <Login />,
  // },
  // {
  //   path: "/bookings",
  //   element: (
  //     <PrivateRoute>
  //       <AdminDahsboardV2 />
  //     </PrivateRoute>
  //   ),
  // },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

// root.render(<LightApp />);

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
