import { Box } from "@mui/material";

export const Row = (props) => {
  const { children, gap = "20px", id, sx } = props;

  return (
    <Box
      sx={{
        display: "flex",
        gap,
        marginTop: "16px",
        width: "100%",
        ...sx,
      }}
      id={id}
    >
      {children}
    </Box>
  );
};
