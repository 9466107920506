export const en = {
  menu: {
    home: "Home",
    highlights: "Highlights",
    facilities: "Facilities",
    "photo-gallery": "Photo gallery",
    "book-now": "Book now",
    contact: "Contact",
  },
  highlights: {
    wifi: "Wi-Fi",
    "dishwasher-machine": "Dishwasher machine",
    barbeque: "Barbeque",
    "kids-playground": "Kids playground",
    kitchen: "Fully equiped kitchen",
    parking: "Free parking",
  },
  facilities: {
    title: "Facilities",
    towels: "Towels and bed linen",
    heating: "Heating",
    internet: "Internet",
    hairdryer: "Hairdryer",
    parking: "Free parking on site",
    stove: "Stove / Fireplace",
    firepit: "Outdoor fire pit",
    dishwasher: "Dishwasher",
    terrace: "Terrace",
    hangers: "Clothes hangers",
    highchair: "High chair",
    desk: "Desk",
    coffeemaker: "Coffee maker",
    showergel: "Shower gel",
    cookware: "Cookware",
    table: "Table",
    kettle: "Kettle",
    homecinema: "Home cinema",
    toiletpaper: "Toilet paper and soap",
    kitchen: "Fully equipped kitchen",
    wifi: "Wireless internet",
    sound: "Sound system",
    freezer: "Freezer",
    books: "Books and reading material",
  },
  photoGallery: {
    title: "Photo gallery",
  },
  booking: {
    title: "Book now",
    step1: {
      title: "Step 1",
      subtitle: "choose the interval",
    },
    step2: {
      title: "Step 2",
      subtitle: " fill the form",
    },
    step3: {
      title: "Step 3",
      subtitle: "submit the request",
    },
    step4: {
      congratulations: "Congratulations!",
      paragraph1:
        "Your request was sent. You will hear from us shortly!",
      paragraph2:
        " If you wish to make a new reservation click on the button bellow",
      buttonLabel: "New reservation",
    },
    form: {
      firstName: "Fist name",
      lastName: "Last name",
      email: "Email",
      phoneNumber: "Phone number",
      kids: "Kids",
      adults: "Adults",
      specialRequest: "Special requests",
    },
    summary: {
      title: "Booking summary",
      adults: "adults",
      kids: "kids",
      nights: "nights",
      notice:
        "Please check if the details you provided are correct before sending your request. You will receive a confirmation email once your request is  approved.",
    },
    back: "Back",
    forward: "Next step",
    sendRequest: "Send request!",
  },
  admin: {
    menu: {
      approvedBookings: "Approved bookings",
      pendingBookings: "Pending bookings",
      allBookings: "All bookings",
      calendar: "Calendar",
      settings: "Settings",
      logout: "Log out",
    },
    settings: {
      title: "Settings",
      language: "Language",
    },
    approvedBookings: {
      noBookings: "No approved bookings",
      title: "Approved bookings",
      guestDetails: {
        title: "Guest details",
        phone: "Phone:",
        email: "Email:",
      },
    },
    pendingBookings: {
      noBookings: "No pending bookings",
      title: "Pending bookings",
      invoiceDetails: {
        title: "Invoice details",
        firstName: "First name",
        lastName: "Last name",
      },
      guestDetails: {
        title: "Guest details",
        phone: "Phone:",
        email: "Email:",
      },
    },
    allBookings: {
      noBookings: "No bookings",
      allBookings: "All bookings",
    },
    calendar: {
      title: "Booking calendar",
      hint1:
        "Click on existing bookings to see their details.",
      hint2: "Click on free dates to make a new booking.",
      addNewBooking: " Add new booking",
      bookingDetails: "Booking details",
      period: "Period:",
      description: "Description:",
      guest: "Guest:",
      removeBooking: "Remove booking",
      confirmRemoveBookingTitle: "Booking remove",
      confirmRemoveBooking:
        "Are you sure you want to remove this booking?",
      confirmNo: "No",
      confirmYes: "Yes, remove it",
    },
    generic: {
      loading: "Loading ...",
      remove: "Remove",
      approve: "Approve",
      removed: "removed",
      save: "Save",
      columns: {
        status: "Status",
        guest: "Guest",
        bookingInterval: "Booking interval",
        bookingRemoved: "Booking removed",
        name: "Name",
        email: "Email",
        phone: "Phone",
      },
      status: {
        approved: "approved",
        pending: "pending",
        special: "special",
      },
    },
  },
};

export const ro = {
  menu: {
    home: "Acasa",
    highlights: "Highlights",
    facilities: "Facilitati",
    "photo-gallery": "Galerie foto",
    "book-now": "Rezerva acum",
    contact: "Contact",
  },
  highlights: {
    wifi: "Wi-Fi",
    "dishwasher-machine": "Masina de spalat vase",
    barbeque: "Gratar",
    "kids-playground": "Loc de joaca pentru copii",
    kitchen: "Bucatarie complet utilata",
    parking: "Parcare gratuita",
  },
  facilities: {
    title: "Facilitati",
    towels: "Prosoape și lenjerie de pat",
    heating: "Încălzire",
    internet: "Internet",
    hairdryer: "Uscător de păr",
    parking: "Parcare gratuită în incintă",
    stove: "Sobă / Șemineu",
    firepit: "Loc amenajat de foc în exterior",
    dishwasher: "Mașină de spălat vase",
    terrace: "Terasă",
    hangers: "Umerașe",
    highchair: "Scaun înalt",
    desk: "Birou de lucru",
    coffeemaker: "Aparat de cafea",
    showergel: "Gel de duș",
    cookware: "Vase de gătit",
    table: "Masă",
    kettle: "Fierbător",
    homecinema: "Home Cinema",
    toiletpaper: "Hârtie igienică și săpun",
    kitchen: "Bucătărie complet utilată",
    wifi: "Internet wireless",
    sound: "Sistem audio",
    freezer: "Congelator",
    books: "Cărți și materiale de lectură",
  },
  photoGallery: {
    title: "Galerie foto",
  },
  booking: {
    title: "Rezerva acum",
    step1: {
      title: "Pasul 1",
      subtitle: "alege intervalul",
    },
    step2: {
      title: "Pasul 2",
      subtitle: " completeaza formularul",
    },
    step3: {
      title: "Pasul 3",
      subtitle: "finalizeaza rezervarea",
    },
    step4: {
      congratulations: "Felicitari!",
      paragraph1:
        "Rezervarea dumneavoastra a fost trimisa cu succes!",
      paragraph2:
        "Daca doriti sa incepeti o noua rezervare apasati pe butonul de mai jos",
      buttonLabel: "Incepe o noua rezervare",
    },
    form: {
      firstName: "Prenume",
      lastName: "Nume",
      email: "Email",
      phoneNumber: "Telefon",
      kids: "Cpii",
      adults: "Adulti",
      specialRequest: "Cerinte speciale",
    },
    summary: {
      title: "Detalii rezervare",
      adults: "adulti",
      kids: "copii",
      nights: "nopti",
      notice:
        "Va rugam sa verificati daca datele completate sunt corecte, inainte de a trimite cererea pentru rezervare. Veti primi un email de confirmare cand cererea dumneavoastra va fi aprobata.",
    },
    back: "Inapoi",
    forward: "Pasul urmator",
    sendRequest: "Trimite",
  },
  admin: {
    menu: {
      approvedBookings: "Rezervari aprobate",
      pendingBookings: "Rezervari in asteptare",
      allBookings: "Toate rezervarile",
      calendar: "Calendar",
      settings: "Setari",
      logout: "Deconectare",
    },
    settings: {
      title: "Setari",
      language: "Limba",
    },
    approvedBookings: {
      noBookings: "Nici o rezervare aprobata.",
      title: "Rezervari aprobate",
      guestDetails: {
        title: "Detalii client",
        phone: "Telefon:",
        email: "Email:",
      },
    },
    pendingBookings: {
      noBookings: "Nici o rezervare in asteptare",
      title: "Rezervari in asteptare",
    },
    allBookings: {
      noBookings: "Nici o rezervare.",
      allBookings: "Toate rezervarile",
    },
    calendar: {
      title: "Calendarul rezervarilor",
      hint1:
        "Apasa pe o rezervare pentru a vedea mai multe detalii.",
      hint2:
        "Apasa pe o data libera pentru a inregistra o rezervare noua",
      addNewBooking: "Adauga o rezervare noua",
      bookingDetails: "Detalii rezervare",
      period: "Perioada:",
      description: "Descriere:",
      guest: "Client:",
      removeBooking: "Sterge rezervare",
      confirmRemoveBookingTitle: "Sterge rezervarea",
      confirmRemoveBooking:
        "Esti sigur ca doresti sa stergi rezervarea?",
      confirmNo: "Nu",
      confirmYes: "Da, starge rezervarea",
    },
    generic: {
      loading: "Se incarca datele ...",
      remove: "Sterge",
      removed: "sters",
      save: "Salveaza",
      approve: "Aproba",
      columns: {
        status: "Status",
        guest: "Client",
        bookingInterval: "Interval rezervare",
        bookingRemoved: "Rezervare anulata",
        name: "Nume",
        email: "Email",
        phone: "Telefon",
      },
      status: {
        approved: "aprobata",
        pending: "in asteptare",
      },
    },
  },
};
