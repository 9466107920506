import { Box } from "@mui/material";
import userIcon from "../assets/svg/user-icon.svg";
import doorICon from "../assets/svg/door.svg";
import showerIcon from "../assets/svg/shower.svg";
import areaIcon from "../assets/svg/area.svg";
import buildingICon from "../assets/svg/building.svg";

export const Highlights = () => {
  return (
    <Box className="section">
      <Box className="section-title">Highlights</Box>
      <Box className="highlights-wrapper">
        <Item label="6 Persoane" imgSrc={userIcon} />
        <Item label="4 Camere" imgSrc={doorICon} />
        <Item label="2 Bai" imgSrc={showerIcon} />
        <Item label="200mp" imgSrc={areaIcon} />
        <Item
          label="Cabana in padure"
          imgSrc={buildingICon}
        />
      </Box>
    </Box>
  );
};

const Item = (props) => {
  const { label, imgSrc } = props;
  return (
    <Box className="high-item">
      <Box sx={{ height: "100%" }}>
        <img
          src={imgSrc}
          style={{ width: "16px" }}
          alt=""
        />
      </Box>
      <Box sx={{ marginLeft: "10px" }}>{label}</Box>
    </Box>
  );
};
