export const formatValue = (value) => {
  // value = 921312432123432
  // value = 123
  // value = 9999
  // value = 912323

  // start from the rightmost number and split into groups of 3

  const valueToString = value.toString().split("");

  let index = valueToString.length - 3;
  while (index > 0) {
    valueToString.splice(index, 0, ".");
    index = index - 3;
  }

  console.log(valueToString.join(""));

  return valueToString.join("");
};
