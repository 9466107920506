import React from "react";
import { Section } from "./Section";
import Foisor from "../assets/images/facilities/foisor.png";

export const Turret = () => {
  return (
    <Section
      title="Foișor - (20mp)"
      items={[
        "barbecue echipat complet",
        "sobă pentru ceaun",
        "cuptor de pizza",
        "lemne de foc",
        "mini frigider",
        "chiuvetă",
        "joc de Foosball ",
        "masă dining pentru 8 persoane",
      ]}
      icon={3}
      iconImg={Foisor}
    />
  );
};
