import React from "react";
import { Section } from "./Section";
import ZadaMica from "../assets/images/facilities/mica.png";

export const SmallZada = () => (
  <Section
    title="Zada mică - (20mp)"
    items={[
      "1 dormitor cu pat dublu",
      "încălzirea se face prin convector cu termostat și șemineu pe lemne",
      "entertainment unit cu pick-up, viniuri și boxe",
      "televizor conectat la internet",
      "toaletă",
    ]}
    icon={5}
    iconImg={ZadaMica}
  />
);
