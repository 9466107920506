import React from "react";
import {
  Paper,
  Button as MaterialButton,
} from "@mui/material";

import logo from "../assets/images/logo.png";

import { Link } from "react-scroll";

import MenuIcon from "@mui/icons-material/Menu";

export const Menu = (props) => {
  const menuItems = [
    { label: "Acasa", anchor: "home" },
    { label: "Descriere", anchor: "description" },
    { label: "Facilitati", anchor: "facilities" },
    { label: "Rules & policies", anchor: "rules" },
  ];

  return (
    <div id="menu-wrapper">
      <Logo />
      <div id="desktop-menu">
        {menuItems.map((menuItem, index) => (
          <MenuItem {...menuItem} key={index} />
        ))}
      </div>
      <MobileMenu menuItems={menuItems} />
    </div>
  );
};

const MenuItem = ({ label, anchor }) => {
  return (
    <div className="menu-item">
      <Link
        to={anchor}
        duration={500}
        offset={-100}
        smooth={true}
      >
        {label}
      </Link>
    </div>
  );
};

const MobileMenu = ({ menuItems }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const menuRef = React.useRef(null);

  React.useEffect(() => {
    if (isExpanded) {
      const clickHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (
          menuRef.current &&
          menuRef.current.contains(e.target)
        ) {
          console.log("CONTAINS");
        } else {
          console.log("DOESN'T CONTAIN");
          setIsExpanded(false);
        }
      };

      document.addEventListener("click", clickHandler);

      return () => {
        document.removeEventListener("click", clickHandler);
      };
    }
  }, [isExpanded]);

  return (
    <div id="mobile-menu">
      <div
        id="button"
        onClick={(e) => {
          setIsExpanded(!isExpanded);
          e.stopPropagation();
          e.preventDefault();
        }}
        style={{
          fontSize: "50px",
          padding: "10px",
          marginRight: "20px",
          display: "flex",
        }}
      >
        <MaterialButton>
          <MenuIcon />
        </MaterialButton>
      </div>
      {isExpanded ? (
        <Paper
          ref={menuRef}
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: 70,
            right: 0,
            color: "#000000",
            background: "#FFFFFF",
            minWidth: "200px",
            alignItems: "center",
            padding: "16px 0",
          }}
        >
          {menuItems.map((menuItem, index) => (
            <MenuItem {...menuItem} key={index} />
          ))}
        </Paper>
      ) : null}
    </div>
  );
};

const Logo = (props) => {
  return (
    <div className="logo-wrapper">
      <img src={logo} alt="" />
    </div>
  );
};
