import React from "react";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Fab,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { useSelector, useDispatch } from "react-redux";

import {
  setCheckIn,
  setCheckOut,
  setSmallZada,
  setLegalEntity,
  clearAllData,
} from "../redux/bookingSlice";

import {
  format,
  addMonths,
  subDays,
  addDays,
  formatISO,
  differenceInDays,
} from "date-fns";

import { LegalEntityForm } from "./LegalEntityForm";

import { makeBookingRequest } from "../api";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { formatValue } from "../helpers";

export const MobileBooking = () => {
  const [isFormVisible, setIsFormVisible] =
    React.useState(false);

  const [unavailableDates, setUnavailableDates] =
    React.useState([]);

  const [availableDates, setAvailableDates] =
    React.useState(null);

  const [currentStep, setCurrentStep] =
    React.useState("PICK_DATES");

  const handleClose = () => {
    setCurrentStep("PICK_DATES");
    setIsFormVisible(false);
  };

  const clearReduxForm = () => {
    dispatch(clearAllData());
  };

  const {
    checkIn,
    checkOut,
    firstName,
    lastName,
    email,
    phone,
    companyName,
    companyID,
    smallZada,
    legalEntity,
  } = useSelector((state) => state.bookingDetails);

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/available-dates`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(({ availableIntervals }) => {
        setUnavailableDates(
          availableIntervals.map((date) => {
            return {
              start: subDays(date.start, 1),
              end: date.end,
            };
          })
        );
      })
      .catch((e) => console.log(e));
  }, []);

  const dispatch = useDispatch();

  let Component = null;

  // eslint-disable-next-line default-case
  switch (currentStep) {
    case "PICK_DATES":
      Component = (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Box sx={{ fontSize: "24px" }}>
              Rezervare noua
            </Box>
            (minim doua nopti)
          </Box>

          <Box>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                height: "40px",
                gap: "20px",
              }}
            >
              <DatePicker
                selected={checkIn}
                onChange={(date) => {
                  dispatch(setCheckIn(date));
                  //   setCheckIn(date);
                  setAvailableDates(
                    getAvailableInterval(
                      date,
                      unavailableDates
                    )
                  );
                }}
                customInput={
                  <CustomInput
                    label="Check-in"
                    placeholder="Check-in"
                  />
                }
                excludeDateIntervals={unavailableDates}
                minDate={new Date()}
                maxDate={addMonths(new Date(), 6)}
                disabledKeyboardNavigation
              />

              <DatePicker
                selected={checkOut}
                onChange={(date) => {
                  // setCheckOut(date);
                  dispatch(setCheckOut(date));
                }}
                customInput={
                  <CustomInput
                    label="Check-out"
                    placeholder="Check-out"
                  />
                }
                openToDate={checkIn}
                includeDateIntervals={availableDates}
                minDate={new Date()}
                maxDate={addMonths(new Date(), 6)}
                disabledKeyboardNavigation
              />
            </Box>
          </Box>

          <Button
            variant="contained"
            sx={{
              marginTop: "20px",
              width: "100%",
            }}
            onClick={() => {
              if (checkIn && checkOut) {
                // setStepTwoVisible(true);
                setCurrentStep("BOOKING_DETAILS");
              }
            }}
            disabled={!checkIn || !checkOut}
          >
            Pasul urmator
          </Button>
        </>
      );
      break;
    case "BOOKING_DETAILS":
      Component = (
        <>
          <Box sx={{ marginTop: "30px" }}>
            <Box
              sx={{
                fontSize: "16px",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              <Box>
                Date de facturare pentru sejurul{" "}
                {`${format(
                  checkIn,
                  "dd.MM.yyyy"
                )} - ${format(checkOut, "dd.MM.yyyy")}`}
              </Box>
              <Box sx={{ fontSize: "14px" }}>
                {`(${differenceInDays(
                  checkOut,
                  checkIn
                )} nopti)`}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              marginLeft: "-11px",
            }}
          >
            <Checkbox
              size="small"
              checked={legalEntity === "juridical"}
              onChange={(e) => {
                const legalEntity = e.target.checked
                  ? "juridical"
                  : "physical";

                dispatch(setLegalEntity(legalEntity));
              }}
            />{" "}
            persoana Juridica?
          </Box>

          <Box>
            <LegalEntityForm legalEntity={legalEntity} />
          </Box>

          <Box
            sx={{
              marginTop: "12px",
              marginLeft: "-11px",
              display: "flex",
              width: "100%",
            }}
          >
            <Checkbox
              size="small"
              checked={smallZada}
              onChange={(e) => {
                // setSmallZadaSelected(e.target.checked);
                dispatch(setSmallZada(e.target.checked));
              }}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              doresc sa rezerv si casuta Zada Mica
            </Box>
          </Box>

          <Box
            sx={{
              marginTop: "12px",
              width: "100%",
              //   background: "red",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Box
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Total de plata
            </Box>
            <Box
              sx={{
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {`${formatValue(
                parseInt(
                  differenceInDays(checkOut, checkIn)
                ) *
                  1200 +
                  (smallZada
                    ? 400 *
                      differenceInDays(checkOut, checkIn)
                    : 0)
              )}  RON`}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "24px",
              width: "100%",
            }}
          >
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              onClick={() => {
                makeBookingRequest(checkIn, checkOut, {
                  firstName,
                  lastName,
                  email,
                  phone,
                  companyName,
                  companyID,
                  legalEntity,
                  smallZada,
                });

                clearReduxForm();
                setCurrentStep("CONFIRMATION");
              }}
              disabled={
                (legalEntity === "physical" &&
                  (!firstName ||
                    !lastName ||
                    !email ||
                    !phone)) ||
                (legalEntity === "juridical" &&
                  (!firstName ||
                    !lastName ||
                    !email ||
                    !phone ||
                    !companyID ||
                    !companyName))
              }
            >
              Trimite solicitarea
            </Button>
          </Box>
        </>
      );
      break;
    case "CONFIRMATION":
      Component = (
        <Box sx={{ marginTop: "24px" }}>
          <Box
            sx={{ display: "flex", alignItems: "center" }}
          >
            <CheckCircleOutlineIcon
              sx={{
                fontSize: "50px",
                color: "green",
                marginRight: "20px",
              }}
            />
            <Box sx={{ fontSize: "18px" }}>
              Rezervarea a fost trimisa cu succes!
            </Box>
          </Box>

          <Box sx={{ marginTop: "20px" }}>
            Te vom contacta in scurt timp pentru a confirma
            datele sejurului.
          </Box>
        </Box>
      );
  }

  React.useEffect(() => {
    const bodyElement = document.querySelector("body");
    if (isFormVisible) {
      bodyElement.style.overflow = "hidden";
    } else {
      bodyElement.style.overflow = "auto";
    }
  }, [isFormVisible]);

  return (
    <>
      <Box id="booking-outer-wrapper__mobile">
        <Fab
          sx={{
            background: "#28a528",
            color: "#FFFFFF",
            "&:hover": {
              background: "#28a528",
            },
            position: "fixed",
            bottom: "20px",
            right: "20px",
          }}
          variant="extended"
          onClick={() => {
            // setCurrentStep("BOOKING_DETAILS")
            setIsFormVisible(true);
          }}
        >
          Rezervă
        </Fab>
        {isFormVisible ? (
          <Backdrop
            open={isFormVisible}
            sx={{ zIndex: "9999" }}
          >
            <Box
              sx={{
                position: "fixed",
                background: "#FFFFFF",
                height: "auto",
                maxHeight: "90vh",
                maxWidth: "80vw",
                borderRadius: "6px",
                padding: "20px 35px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  padding: "15px",
                }}
              >
                <Box>
                  {currentStep === "BOOKING_DETAILS" ? (
                    <ArrowBackIcon
                      onClick={() => {
                        // setStepTwoVisible(false);
                        setCurrentStep("PICK_DATES");
                      }}
                    />
                  ) : null}
                </Box>
                <CloseIcon onClick={handleClose} />
              </Box>
              {Component}
            </Box>
          </Backdrop>
        ) : null}
      </Box>
    </>
  );
};

const CustomInput = React.forwardRef(
  ({ value, onClick, label, placeholder }, ref) => (
    <TextField
      label={label}
      placeholder={placeholder}
      onClick={onClick}
      ref={ref}
      value={value ? format(value, "dd.MM.yyyy") : value}
    />
  )
);

const getAvailableInterval = (checkIn, bookings) => {
  let endDate = null;
  [...bookings]
    .sort((booking1, booking2) =>
      new Date(booking1.start) < new Date(booking2.end)
        ? -1
        : 1
    )
    .forEach((booking) => {
      if (
        new Date(checkIn) < new Date(booking.start) &&
        !endDate
      ) {
        endDate = booking.start;
      }
    });

  // if (endDate) {
  return [
    {
      start: formatISO(checkIn, {
        representation: "date",
      }),
      end: formatISO(addDays(checkIn, 14), {
        representation: "date",
      }),
    },
  ];
  // }
  // return null;
};
