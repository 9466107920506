import React from "react";
import { Section } from "./Section";
import ZadaMare from "../assets/images/facilities/mare.png";

export const BigZada = () => (
  <Section
    title="Zada mare - (90mp)"
    items={[
      "3 dormitoare cu paturi duble și tv conectat la internet",
      "încălzire dormitoare prin convectoare electrice cu termostat",
      "living mare cu canapea și proiector conectat la internet",
      "încălzire prin sobă pe lemne",
      "WiFi",
      "Netflix, YouTube Premium, HBO Max, Digi Online",
      "masă dining 6 - 8 persoane",
      "baie cu duș",
      "cameră de depozitare pentru alimente",
      "bucătărie complet utilată",
      "mașină de spălat vase",
      "mașină de spălat haine",
      "tarasă și balcon",
    ]}
    icon={4}
    iconImg={ZadaMare}
  />
);
