import React from "react";
import {
  Box,
  Button,
  Paper,
  TextField,
} from "@mui/material";

import {
  format,
  addMonths,
  subDays,
  addDays,
  formatISO,
} from "date-fns";

import {
  setCheckIn,
  setCheckOut,
  setBookingModalIsVisible,
} from "../redux/bookingSlice";
import { MobileBooking } from "./MobileBooking";

import { useSelector, useDispatch } from "react-redux";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export const BookingV2 = (props) => {
  const { checkIn, checkOut, bookingModalIsVisible } =
    useSelector((state) => state.bookingDetails);

  // const [availabilityResponse, setAvailabilityResponse] =
  //   React.useState("");

  const dispatch = useDispatch();

  return (
    <>
      {!bookingModalIsVisible ? (
        <Paper
          elevation={3}
          id="booking-outer-wrapper"
          sx={{
            boxSizing: "border-box",
            // zIndex: 2000,
            background: "white",
            position: "sticky",
            top: "150px",
            right: "50px",
            width: "550px",
            height: "230px",
            // display: "flex",
            marginTop: "150px",
            padding: "20px 40px",
            border: "1px solid #cecece",
            borderRadius: "6px",
          }}
        >
          <Box
            id="booking-inner-wrapper"
            sx={{ overflow: "hidden" }}
          >
            <AvailabilityCheck
              checkIn={checkIn}
              setCheckIn={(date) =>
                dispatch(setCheckIn(date))
              }
              checkOut={checkOut}
              setCheckOut={(date) =>
                dispatch(setCheckOut(date))
              }
            />
          </Box>
        </Paper>
      ) : null}
      <MobileBooking />
    </>
  );
};

const CustomInput = React.forwardRef(
  (
    { value, onClick, label, placeholder, forceDisable },
    ref
  ) => {
    return (
      <TextField
        label={label}
        placeholder={placeholder}
        onClick={onClick}
        ref={ref}
        value={value ? format(value, "dd.MM.yyyy") : value}
        disabled={forceDisable}
      />
    );
  }
);

const AvailabilityCheck = (props) => {
  const { checkIn, setCheckIn, checkOut, setCheckOut } =
    props;

  // const [unavailableDates, setUnavailableDates] =
  //   React.useState([]);

  const [availableDateDB, setAvailableDatesDB] =
    React.useState([]);

  const [availableDates, setAvailableDates] =
    React.useState(null);

  const { bookingComplete } = useSelector(
    (state) => state.bookingDetails
  );

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/available-dates`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(({ availableIntervals }) => {
        // console.log(availableIntervals);
        // console.log("Bookings from DB");

        setAvailableDatesDB(
          availableIntervals.map((date) => {
            return {
              start: date.start,
              end: date.end,
            };
          })
        );
      })
      .catch((e) => console.log(e));
  }, []);

  const dispatch = useDispatch();

  return (
    <>
      {!bookingComplete ? (
        <>
          <Box
            sx={{
              fontSize: "24px",
              fontWeight: "500",
            }}
          >
            Rezerva acum
          </Box>
          <Box
            sx={{
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            minim 2 nopti
          </Box>
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              height: "40px",
              gap: "20px",
            }}
          >
            <DatePicker
              selected={checkIn}
              onChange={(date) => {
                setCheckIn(
                  formatISO(date, {
                    representation: "date",
                  })
                );
                setAvailableDates(
                  getAvailableInterval(
                    date,
                    availableDateDB
                  )
                );
              }}
              customInput={
                <CustomInput
                  label="Check-in"
                  placeholder="Check-in"
                />
              }
              includeDateIntervals={availableDateDB}
              minDate={new Date()}
              maxDate={addMonths(new Date(), 6)}
              disabledKeyboardNavigation
              calendarClassName="client-calendar"
            />

            <DatePicker
              selected={checkOut}
              onChange={(date) =>
                setCheckOut(
                  formatISO(date, {
                    representation: "date",
                  })
                )
              }
              customInput={
                <CustomInput
                  label="Check-out"
                  placeholder="Check-out"
                  forceDisable={checkIn === null}
                />
              }
              openToDate={checkIn}
              includeDateIntervals={availableDates}
              minDate={new Date()}
              maxDate={addMonths(new Date(), 6)}
              disabledKeyboardNavigation
              calendarClassName="client-calendar"
              readOnly={!checkIn}
            />
          </Box>

          <Button
            variant="contained"
            sx={{ marginTop: "20px", width: "100%" }}
            onClick={() => {
              if (checkIn && checkOut) {
                dispatch(setBookingModalIsVisible(true));
              }
            }}
            disabled={!checkIn || !checkOut}
          >
            Pasul urmator
          </Button>
        </>
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "240px",
            // background: "red",
            display: "flex",
            flexDirection: "column",
            padding: "0 50px",
            alignItems: "center",
            justifyContent: "center",
          }}
          id="confirmation-wrapper"
        >
          <Box
            sx={{
              fontWeight: "500",
              fontSize: "20px",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            Cererea de rezervare a fost trimisa cu succes!
          </Box>
          <Box>
            Va vom contacta in scurt timp pentru a confirma
            datele completate.
          </Box>
        </Box>
      )}
    </>
  );
};

const formatDateISO = (date) =>
  formatISO(date, { representation: "date" });

const getAvailableInterval = (
  checkIn,
  availableIntervals
) => {
  console.log(
    `calling getAvailableInterval for ${checkIn}`
  );

  // console.log(availableIntervals);
  let endDate = null;
  [...availableIntervals]
    // .sort((booking1, booking2) =>
    //   formatDateISO(new Date(booking1.start)) <
    //   formatDateISO(new Date(booking2.start))
    //     ? -1
    //     : 1
    // )
    .forEach((availableInterval) => {
      console.log(
        formatDateISO(new Date(availableInterval.end))
      );

      if (
        formatDateISO(new Date(checkIn)) <
          formatDateISO(new Date(availableInterval.end)) &&
        !endDate
      ) {
        endDate = availableInterval.end;
      }
    });

  console.log(`endDate = ${endDate}`);
  // if (endDate) {
  return [
    {
      start: formatDateISO(checkIn),
      end: formatDateISO(endDate ?? addDays(checkIn, 14)),
    },
  ];
  // }
  // return null;
};
