import { Box } from "@mui/material";

import img1 from "../assets/images/facilities/1.png";
import img2 from "../assets/images/facilities/2.png";
import img3 from "../assets/images/facilities/3.png";
import img4 from "../assets/images/facilities/4.png";
import img5 from "../assets/images/facilities/5.png";
import img6 from "../assets/images/facilities/6.png";
import img7 from "../assets/images/facilities/7.png";
import img8 from "../assets/images/facilities/8.png";
import img9 from "../assets/images/facilities/9.png";
import img10 from "../assets/images/facilities/10.png";
import img11 from "../assets/images/facilities/11.png";
import img12 from "../assets/images/facilities/12.png";
import img13 from "../assets/images/facilities/13.png";
import img14 from "../assets/images/facilities/14.png";
import img15 from "../assets/images/facilities/15.png";
import img16 from "../assets/images/facilities/16.png";
import img17 from "../assets/images/facilities/17.png";
import img18 from "../assets/images/facilities/18.png";
import img19 from "../assets/images/facilities/19.png";
import img20 from "../assets/images/facilities/20.png";
import img21 from "../assets/images/facilities/21.png";

import tv from "../assets/svg/tv.svg";
import toaster from "../assets/svg/toaster.svg";
import genericItem from "../assets/svg/generic-item.svg";

export const Amenities = () => {
  const facilities = [
    { label: "curte 600 mp", img: img1 },
    { label: "loc de joacă pentru copii", img: img2 },
    { label: "sezlonguri", img: img3 },
    { label: "saună", img: img4 },
    { label: "terasă", img: img5 },
    { label: "barbecue complet echipat", img: img6 },
    { label: "cuptor pizza", img: img7 },
    { label: "bucătărie complet echipată", img: img8 },
    { label: "cuptor", img: img9 },
    { label: "toaster", img: img10 },
    { label: "espressor automat", img: img11 },
    { label: "selecție de ceaiuri", img: img12 },
    { label: "filtru pentru apă potabilă", img: img13 },
    { label: "mașină de spălat vase", img: img14 },
    { label: "mașină de spălat haine", img: img15 },
    { label: "proiector și boxe bluetooth", img: img16 },
    { label: "tv", img: img17 },
    { label: "Wi-Fi", img: img18 },
    { label: "masa foosball", img: img19 },
    { label: "șemineu", img: img20 },
    { label: "balcon", img: img21 },
  ];

  return (
    <Box className="section" id="facilities">
      {/* <Box className="section-title">Facilitati</Box>
      <Box className="facilities-wrapper">
        {facilities.map((item, index) => (
          <Item {...item} size="26px" key={index} />
        ))}
      </Box> */}

      <Box
        className="section-title"
        sx={{ marginTop: "50px" }}
      >
        Facilitati
      </Box>
      <Box className="facilities-wrapper">
        {facilities.map((item, index) => (
          <Item {...item} size="20px" key={index} />
        ))}
      </Box>

      {/* <Box
        className="section-title"
        sx={{ marginTop: "50px" }}
      >
        Facilitati 3
      </Box>
      <Box className="facilities-wrapper">
        {facilities.map((item, index) => (
          <Item {...item} size="16px" key={index} />
        ))}
      </Box> */}
    </Box>
  );
};

const Item = (props) => {
  const { label, img, size } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img
          src={img}
          alt=""
          style={{
            marginRight: "10px",
            width: size,
            height: size,
          }}
        />
      </Box>
      <Box>{label}</Box>
    </Box>
  );
};
