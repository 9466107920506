import { formatISO } from "date-fns";

export const makeBookingRequest = (
  checkIn,
  checkOut,
  guestDetails,
  successCallback
) => {
  const body = {
    checkIn: formatISO(checkIn, {
      representation: "date",
    }),
    checkOut: formatISO(checkOut, {
      representation: "date",
    }),
    ...guestDetails,
  };

  fetch(
    `${process.env.REACT_APP_URL}/api/booking-request`,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      successCallback && successCallback();
      console.log(res);
      // clearForm();
    })
    .catch((e) => console.log(e));
};
