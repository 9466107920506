import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  legalEntity: "physical",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  companyName: "",
  companyID: "",
  // checkIn: new Date(2024, 3, 1),
  checkIn: null,
  // checkOut: new Date(2024, 3, 6),
  checkOut: null,
  smallZada: false,
  bookingModalIsVisible: false,
  bookingComplete: false,
};

export const bookingSlice = createSlice({
  name: "bookingSlice",
  initialState,
  reducers: {
    setFirstName: (state, { payload }) => {
      state.firstName = payload;
    },
    setLastName: (state, { payload }) => {
      state.lastName = payload;
    },
    setEmail: (state, { payload }) => {
      state.email = payload;
    },
    setPhone: (state, { payload }) => {
      state.phone = payload;
    },
    setCompanyName: (state, { payload }) => {
      state.companyName = payload;
    },
    setCompanyID: (state, { payload }) => {
      state.companyID = payload;
    },
    setSmallZada: (state, { payload }) => {
      state.smallZada = payload;
    },
    setLegalEntity: (state, { payload }) => {
      state.legalEntity = payload;
    },
    setCheckIn: (state, { payload }) => {
      state.checkIn = payload;
    },
    setCheckOut: (state, { payload }) => {
      state.checkOut = payload;
    },
    setBookingModalIsVisible: (state, { payload }) => {
      state.bookingModalIsVisible = payload;
    },
    setBookingComplete: (state) => {
      state.bookingComplete = true;
    },
    clearAllData: (state) => {
      state.firstName = "";
      state.lastName = "";
      state.checkIn = null;
      state.checkOut = null;
      state.email = "";
      state.phone = "";
      state.companyID = "";
      state.companyName = "";
      state.legalEntity = "physical";
      state.smallZada = false;
      state.bookingModalIsVisible = false;
      state.bookingComplete = false;
    },
  },
});

export const {
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  setCompanyName,
  setCompanyID,
  setSmallZada,
  setLegalEntity,
  setCheckIn,
  setCheckOut,
  setBookingModalIsVisible,
  setBookingComplete,
  clearAllData,
} = bookingSlice.actions;

export default bookingSlice.reducer;
