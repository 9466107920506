import { configureStore } from "@reduxjs/toolkit";
import { bookingSlice } from "./redux/bookingSlice";
// import { adminBookingSlice } from "./redux/adminBookingSlice";
// import { selectedBookingSlice } from "./redux/selectedBookingSlice";
// import { pricingBaseSettingsSlice } from "./redux/pricingBaseSettingsSlice";
// import { pricingSlice } from "./redux/pricingSlice";
// import { newBookingSlice } from "./redux/newBookingSlice";
// import { updateBookingSlice } from "./redux/updateBookingSlice";

export default configureStore({
  reducer: {
    bookingDetails: bookingSlice.reducer,
    // adminBookings: adminBookingSlice.reducer,
    // newBooking: newBookingSlice.reducer,
    // updateBooking: updateBookingSlice.reducer,
    // selectedBooking: selectedBookingSlice.reducer,
    // pricingBaseSettings: pricingBaseSettingsSlice.reducer,
    // customPricing: pricingSlice.reducer,
  },
});
