import React from "react";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export const Footer = () => {
  return (
    <div className="footer-section">
      <div className="footer-section-title">Contact</div>
      <div className="footer-section-description">
        <div
          className="contact-item"
          onClick={() => {
            window.open(
              "https://wa.me/40756622487",
              "_BLANK"
            );
          }}
        >
          <WhatsAppIcon />
          {` 0756 622 487`}
        </div>
        <div
          className="contact-item"
          onClick={() => {
            window.open(
              "https://instagram.com/zadanaturelounge",
              "_BLANK"
            );
          }}
        >
          <InstagramIcon />
          {`/zadanaturelounge`}
        </div>
        <div
          className="contact-item"
          onClick={() => {
            window.open(
              "https://www.facebook.com/zadanaturelounge/",
              "_BLANK"
            );
          }}
        >
          <FacebookIcon />
          {`/zadanaturelounge`}
        </div>
      </div>
    </div>
  );
};
