import React from "react";

export const ImageComponent = (props) => {
  const { vLowRes, lowRes, highRes, index } = props;
  const [imageLoaded, setImageLoaded] =
    React.useState(false);

  const imageRef = React.useRef(null);

  React.useEffect(() => {
    const width =
      document.querySelector("body").clientWidth;

    if (width > 600 && imageLoaded) {
      imageRef.current.src = `/assets/gallery/${highRes}`;
    } else if (width <= 600 && imageLoaded) {
      imageRef.current.src = `/assets/gallery/${lowRes}`;
    }
  }, [imageLoaded]);

  return (
    <img
      ref={imageRef}
      src={`/assets/gallery/${vLowRes ?? lowRes}`}
      style={{
        width: "45vw",
        minWidth: "45vw",
      }}
      className="carousel-img-item"
      alt=""
      key={index}
      onLoad={() => {
        setImageLoaded(true);
      }}
    />
  );
};
