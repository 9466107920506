import React from "react";
import "./App.css";

import { useSelector } from "react-redux";

import { Highlights } from "./components/Highlights";
import { Amenities } from "./components/Amenities";
import { Carousel } from "./components/Carousel";
import { Footer } from "./components/Footer";
import { Box, Backdrop } from "@mui/material";
import { LoadingPage } from "./components/LoadingPage";
import { BigZada } from "./components/BigZada";
import { SmallZada } from "./components/SmallZada";
import { Turret } from "./components/Turret";
import { Menu } from "./components/Menu";
import { BookingV2 } from "./components/BookingV2";
import { BookingForm } from "./components/BookingForm";

export const App = (props) => {
  // const [checkIn, setCheckIn] = React.useState(new Date());
  // const [checkOut, setCheckOut] = React.useState(
  //   addDays(new Date(), 5)
  // );

  const [imageWidth, setImageWidth] = React.useState(null);
  const [imageHeight, setImageHeight] =
    React.useState(null);

  React.useEffect(() => {
    const bodyWidth =
      document.querySelector("body").clientWidth;

    setImageWidth(bodyWidth * 0.45);
    setImageHeight((bodyWidth * 0.45) / 1.49);
  }, []);

  const isModalVisible = useSelector(
    (state) => state.bookingDetails.bookingModalIsVisible
  );

  if (!imageWidth || !imageHeight) {
    return <LoadingPage />;
  }

  return (
    <div className="main-wrapper">
      <Menu />
      <Content
        imageWidth={imageWidth}
        imageHeight={imageHeight}
      />
      {isModalVisible ? (
        <Backdrop open sx={{ zIndex: "9200" }}>
          <BookingForm />
        </Backdrop>
      ) : null}
    </div>
  );
};

const Content = (props) => {
  const { imageWidth, imageHeight } = props;
  return (
    <div className="content-wrapper" id="home">
      <Carousel
        imageWidth={imageWidth}
        imageHeight={imageHeight}
      />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flex: 1 }}>
          <Name />
          <Highlights />
          <Description />
          <BigZada />
          <SmallZada />
          <Turret />
          <Amenities />
          <Map />
          <CostDetails />
          <Access />
          <RulesAndPolicies />
        </Box>
        <BookingV2 />
      </Box>
      <Footer />
    </div>
  );
};

const Name = () => {
  return (
    <Box className="name-section">
      <Box className="name-section-title">
        Zada Nature Lounge
      </Box>
      <Box className="name-section-description">
        judetul Cluj, Casele Micesti 8D, 407271
      </Box>
    </Box>
  );
};

const Description = () => {
  return (
    <Box className="section" id="description">
      <Box className="section-title">Descriere</Box>
      <div className="about-description-wrapper">
        <div className="paragraph">
          Cabana Zada Nature lounge este locul unde evadezi
          în natură la doar 10 km de Cluj Napoca. Este
          potrivită atât pentru grupuri mici de prieteni cât
          și pentru familii cu copii. Proprietatea este
          înconjurată de pădure, chiar după gard începe Sit
          Natura 2000 Făgetul Clujului unde se pot găsi
          numeroase trasee de hike și mountain bike.
        </div>
        <div className="paragraph">
          Proprietatea poate găzdui 10 persoane în cele 2
          corpuri, Zada Mare și Zada Mică. Parcarea este în
          cadrul proprietății, supravegheată video și are o
          capacitate de 4 mașini. Locuri adiționale de
          parcare sunt disponibile în fața proprietății.
        </div>
      </div>
    </Box>
  );
};

const Map = () => {
  return (
    <Box className="section">
      <Box className="section-title">Harta</Box>
      <iframe
        width="100%"
        height="400px"
        loading="lazy"
        // allowfullscreen
        referrerPolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCdegxlU5oICcC70zsLc-yUt_YRTP-ico8
    &q=MHXF+XP Casele Micești&zoom=15&center=46.69995205101875, 23.57464391868823"
        title="abc"
      ></iframe>
    </Box>
  );
};

const CostDetails = () => {
  return (
    <Box className="section">
      <Box className="section-title">Pret</Box>
      <Box sx={{ fontWeight: "500" }}>
        Zada Mare - 1200 de lei / noapte
      </Box>
      <Box>
        Se închiriază doar integral, pentru minim 2 nopți.
        Capacitatea maximă este de 8 persoane, copii peste 8
        ani ocupă un loc de cazare. Nu dispunem de paturi
        suplimentare
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <Box sx={{ fontWeight: "500" }}>
          Zada Mică - 300 de lei / noapte
        </Box>
        Se închiriază la cerere, doar împreună cu Zada Mare,
        doar aceluiași grup, pentru același număr de nopți.
        Dacă nu se dorește închirierea, Zada Mică rămâne
        neocupată.
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <Box sx={{ fontWeight: "500" }}>
          Politică Anulare
        </Box>
        <Box>
          În cazul anulării unei rezervări, sumele plătite
          în avans (50%) nu sunt returnabile. Cu până la 30
          de zile înainte de check-in datele rezervării se
          pot schimba o singură dată.
        </Box>
      </Box>
      {/* <Box sx={{ marginTop: "10px" }}>
        <Box sx={{ fontWeight: "500" }}>Acces</Box>
        <Box>
          Accesul se face dinspre comuna Feleacu. De unde se
          termină asfaltul mai sunt aproximativ 3 km până la
          proprietate, dar drumul este pietruit și
          practicabil cu orice fel de mașină. Când ajungeți
          la proprietate, din drumul principal faceți
          dreapta și aveți 50 m până la cabană. Veți vedea
          indicatorul ZADA Nature Lounge Cabana se găsește
          pe Waze și Google Maps sub „ZADA Nature Lounge”
        </Box>
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <span style={{ fontWeight: "bold" }}>
          IMPORTANT!
        </span>{" "}
        Mai este o cale de acces, dinspre comuna Ciurila,
        dar drumul este impracticabil.
      </Box> */}
    </Box>
  );
};

const Access = () => {
  return (
    <Box className="section" id="rules">
      <Box className="section-title">Acces</Box>
      <Box>
        Accesul se face dinspre comuna Feleacu. De unde se
        termină asfaltul mai sunt aproximativ 3 km până la
        proprietate, dar drumul este pietruit și practicabil
        cu orice fel de mașină. Când ajungeți la
        proprietate, din drumul principal faceți dreapta și
        aveți 50 m până la cabană. Veți vedea indicatorul
        ZADA Nature Lounge Cabana se găsește pe Waze și
        Google Maps sub „ZADA Nature Lounge”
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <span style={{ fontWeight: "bold" }}>
          IMPORTANT!
        </span>{" "}
        Mai este o cale de acces, dinspre comuna Ciurila,
        dar drumul este impracticabil.
      </Box>
    </Box>
  );
};

const RulesAndPolicies = () => {
  return (
    <Box className="section" id="rules">
      <Box className="section-title">Rules & policies</Box>
      <Box>Check-in: 15:00</Box>
      <Box>Check-out: 11:00</Box>
    </Box>
  );
};
